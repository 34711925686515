import './polyfills';

import { initMobileNav, initPhoneOverlay } from './header';
import { initFacilityMobile, initFacilityCaro, facilityMap, initFacilityTabs, initFacilityFilters, initNearbyMap } from './facility';
import { initReviewsCaro } from './reviewsCaro';
import { contactSubmit } from './contact.js';
import { homeHero, homeTabs, homeCaros, multiMap } from './home';
import { initTipsSelector } from './help';

initMobileNav();
initPhoneOverlay();

if(document.querySelector('#facility_info')) {
	initFacilityMobile();
}

if(document.querySelector('#facility_caro')) {
	initFacilityCaro();
}

if(document.querySelector('#facility_map')) {
	facilityMap();
}

if(document.querySelector('#facility_content_tabs')) {
	initFacilityTabs();
}

if(document.querySelector('#facility_filters')) {
	initFacilityFilters();
}

if(document.querySelector('#facility_nearby_map')) {
	initNearbyMap();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#tips_selectors')) {
	initTipsSelector();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'facility', 'email', 'message']
	});
}

if (document.getElementById('home_hero_wrap')) {
	homeHero();
}
if (document.getElementById('home_about_links')) {
	homeTabs();
	homeCaros();
}
if (document.getElementById('multi_map')) {
	multiMap();
}
